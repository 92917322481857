@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --text-main: #030a21;
}
@layer components {
  .absolute-center {
    @apply absolute top-1/2 left-1/2 
    -translate-x-1/2 
    -translate-y-1/2;
  }
  .absolute-cover {
    @apply absolute top-0 left-0 w-full h-full;
  }
  .flex-center {
    @apply flex justify-center items-center;
  }
  .my-tbody {
    @apply divide-y divide-gray-200 bg-white;
  }
  .my-tr {
    @apply transition hover:bg-gray-100;
  }
  .my-td {
    @apply py-2 px-6 text-sm font-medium text-gray-900;
  }
  .my-th {
    @apply py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700;
  }
  .my-table {
    @apply min-w-full table-fixed divide-y divide-gray-200 border;
  }
  .my-thead {
    @apply bg-gray-100;
  }
  .my-input {
    @apply block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm;
  }
  .my-label {
    @apply mb-2 block text-sm font-medium;
  }
  .my-heading {
    @apply mb-2 block text-2xl font-medium;
  }
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04aa6d;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04aa6d;
    cursor: pointer;
  }
  .webinar-popup-signin-button-link {
    text-decoration: none;
  }

  .webinar-popup-signin-button {
    padding: 10px 0;
    width: 100%;
    border-radius: 1000px;
    background-color: transparent;
    border: 1px solid var(--text-main);
    color: var(--text-main);
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  .webinar-popup-signin-button:focus {
    background: rgba(3, 10, 33, 0.12);
  }
  .webinar-popup-signin-icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
  }
}

body {
  padding: 0 16px;
}

@layer components {
  .truncate-3-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
